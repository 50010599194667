<template>
  <div class="flex flex-col gap-5">
    <fw-panel :title="'Detalhes gerais'" featured :loading="saving" after-loading-checked class="mb-5">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div
            v-if="editMode && teamErrors.name.$error"
            class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
          >
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">Existem erros no formulário</span>
          </div>
          <div v-if="validations.can_edit_team" class="flex gap-5">
            <fw-button :type="!editMode ? 'link' : 'link-light'" :disabled="saving" @click.native="toggleEditMode()">
              {{ editMode ? 'Fechar' : 'Editar' }}
            </fw-button>
            <fw-button v-if="editMode" type="primary" :disabled="saving" :loading="saving" @click.native="saveTeam()"
              >Guardar</fw-button
            >
          </div>
        </div>
      </template>

      <div v-if="editMode" class="flex gap-5">
        <div class="text-center w-1/3">
          <Uploader
            reference-id="team-image"
            allowed="images"
            bucket-type="image"
            :is-docked="true"
            :is-custom="true"
            :clear-after="true"
            :limit="1"
            :path-to-upload="photoUploaderUrl"
            @upload="$emit('update-logo', $event)"
          >
            <div slot="navbar">
              <div class="flex flex-col items-center p-5">
                <div class="h-36 w-36 relative">
                  <TeamBackground
                    class="absolute overflow-visible h-36 w-36 drop-shadow-xl top-0 left-0"
                    :style="{ color: team.color ?? '#EFEFEF' }"
                  />
                  <div
                    v-if="!team.logo"
                    class="h-24 w-24 rounded-lg text-2xl flex justify-center items-center uppercase text-opacity-70 font-bold overflow-hidden absolute top-6 left-6"
                  >
                    <TeamImagePlaceholder class="h-24 w-24" />
                  </div>
                  <img
                    v-else
                    :src="getTeamLogoUrl(team.logo)"
                    class="transition-opacity h-24 w-24 object-cover object-center rounded-lg text-2xl flex justify-center items-center uppercase text-opacity-70 font-bold absolute top-6 left-6"
                    :class="logoLoaded ? 'opacity-100' : 'opacity-0'"
                    @load="logoLoaded = true"
                  />
                  <div
                    v-if="!logoLoaded && team.logo"
                    class="transition-all h-24 w-24 rounded-lg absolute top-6 left-6 flex justify-center items-center text-white"
                    :style="{ backgroundColor: team.color ?? '#EFEFEF' }"
                  >
                    <fw-icon-loading class="animate-spin w-10 h-10" />
                  </div>
                </div>

                <a href="#" class="text-xs font-semibold text-gray-600 mt-4">Define uma imagem</a>
              </div>
            </div>
          </Uploader>
          <a v-if="team.logo" href="#" class="text-xs text-red-500" @click="$emit('remove-logo')">Remover</a>
        </div>
        <div class="flex-1 flex gap-5 flex-col items-start justify-center">
          <div>
            <fw-label>Indica um nome</fw-label>
            <b-input
              v-model="team.name"
              :minlength="1"
              :maxlength="50"
              :class="{
                error: teamErrors.name.$error
              }"
              expanded
            ></b-input>
            <fw-tip v-if="teamErrors.name.$error" error>Insira um nome para a sua equipa </fw-tip>
          </div>
          <div>
            <fw-label>Escolhe uma cor</fw-label>
            <div class="flex gap-3">
              <button
                v-for="(color, c) in availableColors"
                :key="'color_' + c"
                :style="{ backgroundColor: color }"
                :class="{ 'border-gray-200': color != team.color, ' border-primary border-2': color == team.color }"
                class="h-10 w-10 border rounded-full"
                role="button"
                @click="$emit('select-color', color)"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex gap-5 items-center">
        <TeamAvatar size="lg" with-shadow :team="team" />
        <div class="flex-1 flex flex-col gap-5">
          <div>
            <fw-heading size="h1" :muted="!team.name">{{ team.name ?? 'Equipa sem nome' }}</fw-heading>
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="'Elementos da equipa'" :counter="totalPlayers" featured>
      <template v-if="validations.can_edit_team" #toolbar>
        <fw-button type="link" @click.native="$emit('add-player')">Adicionar</fw-button>
      </template>

      <fw-panel-info v-if="totalPlayers < sport?.min_players" type="orange" boxed clean icon class="mb-5">
        <span>Uma equipa tem de ter pelo menos {{ sport?.min_players }} elementos.</span>
      </fw-panel-info>
      <fw-panel-info v-if="totalPlayers > sport?.max_players" type="orange" boxed clean icon class="mb-5">
        <span>Uma equipa só pode ter no máximo {{ sport?.max_players }} elementos.</span>
      </fw-panel-info>

      <slot name="players"></slot>

      <fw-panel :title="'Confirmados'" class="mb-5" :counter="team.players?.length ?? 0">
        <div class="flex gap-5 flex-wrap">
          <div
            v-for="player in team.players"
            :key="player.key"
            class="bg-white rounded-lg p-2 flex flex-col gap-4 w-60"
          >
            <div class="flex h-7 z-10">
              <div v-if="team.leader.key == player.key" class="flex-1 flex gap-1 items-center">
                <fw-icon-start-smile-solid class="text-primary h-5 w-5" />
                <span class="font-bold leading-5 my-1.5">Líder</span>
              </div>
              <b-dropdown aria-role="list" class="ml-auto" append-to-body>
                <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                  <fw-icon-more class="w-5 h-5" />
                </fw-button-dropdown>
                <b-dropdown-item
                  v-if="validations.can_edit_team && team.leader.key != player.key"
                  custom
                  aria-role="menuitem"
                  class="paddingless"
                >
                  <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('set-leader', player)"
                    >Definir como líder</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="validations.can_edit_team && team.leader.key != player.key && totalPlayers > 1"
                  custom
                  aria-role="menuitem"
                  class="paddingless"
                >
                  <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('delete-player', player)"
                    >Remover</fw-button
                  >
                </b-dropdown-item>
                <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                  <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('open', player.key)"
                    >Ver detalhes</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <fw-avatar :user="player" size="lg" class="m-auto" />
            <div class="text-center">
              <p class="font-bold">{{ player.name }}</p>
              <p class="text-gray-500 text-xs">{{ player.email }}</p>
            </div>
            <div class="text-center text-xs text-gray-500">
              Inscrito em {{ player.decision_date ?? team.updated_at | formatDateTime }}
            </div>
          </div>
        </div>
      </fw-panel>

      <fw-panel :title="'Convites pendentes'" class="my-3" :counter="team.invites?.length ?? 0" boxed="xs">
        <RecordInvite
          v-for="invite in team.invites"
          :key="invite.key"
          :invite="invite"
          :allow-resend="false"
          :can-remove="validations.can_edit_team && invite.status == 'pending'"
          :allow-open="!!editionSignups[invite.user_key]"
          @open="$emit('open', invite.user_key)"
          @resend="$emit('resend-invite', invite)"
          @delete="$emit('delete-player', invite)"
        ></RecordInvite>
        <fw-panel-info v-if="team == null || !team.invites || team.invites?.length == 0" clean boxed>
          Não existem convites pendentes.
        </fw-panel-info>
      </fw-panel>
    </fw-panel>
  </div>
</template>

<script>
import TeamAvatar from '@/components/TeamAvatar'
import RecordInvite from '@/components/records/RecordInvite'
import TeamImagePlaceholder from '@/components/icons/TeamImagePlaceholder'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import TeamBackground from '@/components/icons/TeamBackground'

import { TEAM_COLORS } from '@/utils/index'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'

export default {
  components: {
    TeamAvatar,
    RecordInvite,
    Uploader,
    TeamImagePlaceholder,
    TeamBackground
  },

  props: {
    teamErrors: {
      type: Object
    },
    team: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },
    sport: {
      type: Object,
      default: () => {}
    },
    editionSignups: {
      type: Object,
      default: () => {}
    },
    validations: {
      type: Object,
      default: () => {}
    },
    saving: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      photoUploaderUrl: FwEnvConfig.apiUrlStorage + '/v1/file',
      availableColors: TEAM_COLORS,
      logoLoaded: false,
      editMode: false
    }
  },

  computed: {
    totalPlayers() {
      if (this.team) return (this.team.players?.length ?? 0) + (this.validInvites?.length ?? 0)
      return 0
    },

    validInvites() {
      return this.team.invites.filter(el => el.status == 'confirmed' || el.status == 'pending')
    },

    user() {
      return this.$store.getters.getUser
    }
  },

  watch: {
    'team.logo'(newVal, oldVal) {
      if (oldVal && newVal?.key != oldVal?.key) {
        this.logoLoaded = false
      }
    }
  },

  methods: {
    getTeamLogoUrl(image) {
      if (!image) return
      return ServiceStorage.getImageUrl(image, 'max2k', null)
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    saveTeam() {
      this.$emit('save-team')
    }
  }
}
</script>
