<template>
  <fw-layout
    full
    :back-to="backToPath"
    :loading="loading.initial"
    right-sidebar-width="w-80"
    main-sidebar-width="w-80"
    :notfound="notFoundTeam"
    loading-title="Equipa"
  >
    <template #main-sidebar>
      <SidebarManageTeamsList
        :loading="loading.teams"
        :teams="teams"
        :users="users"
        :active-team="team ? team.key : 'none'"
        :pagination="pagination.teams"
        @go-to-team="goToTeam"
        @page-changed="teamsPageChanged"
      />
    </template>

    <template #main-content>
      <div class="flex-1 border-l border-gray-100 relative flex" :class="{ 'blur-sm opacity-40': loading.team }">
        <div class="px-10 py-5 flex-1 2xl:w-3/4 overflow-auto flex flex-col gap-10">
          <PanelManageTeamHeader
            :team="team"
            :users="users"
            :validations="validations"
            :team-errors="$v.team"
            :sport="sport"
            :saving="savingData"
            :edition-signups="editionSignups ?? {}"
            @open="openEditionSignupModal"
            @save-team="updateTeam()"
            @resend-invite="resendInvite"
            @update-logo="updateLogo"
            @remove-logo="removeLogo"
            @select-color="selectColor"
            @add-player="addTeamMemberModalActive = true"
            @delete-player="removeTeamMember"
            @set-leader="setLeader"
          >
            <template #players>
              <fw-panel-info v-if="team.status === 'error'" type="orange" boxed clean icon class="mb-5">
                {{ $t('errors.duplicatedPlayers') }}
              </fw-panel-info>
              <fw-panel-info
                v-else-if="validations.stage_is_full && team.status === 'submitted'"
                type="orange"
                boxed
                clean
                icon
                class="mb-5"
              >
                {{ $t('errors.stageIsFull') }}
              </fw-panel-info>
            </template>
          </PanelManageTeamHeader>

          <fw-panel title="Liga" boxed="xs">
            <div
              class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-3"
            >
              <div class="flex-shrink-0 font-medium flex items-center gap-1">
                <fw-icon-arrow-right class="w-5 h-5 text-gray-500" />
              </div>

              <div class="flex-1 font-medium capitalize flex gap-3 min-w-0">
                <div class="truncate">{{ edition?.name ?? '' }} {{ league.title[language] }}</div>
              </div>
            </div>
          </fw-panel>

          <fw-panel :title="$t('tournament')" boxed="xs">
            <div>
              <RecordTournament
                :tournament="tournament"
                :sport="sport"
                :is-academic="isAcademic"
                @open="goToTournament(tournament.key)"
              />
            </div>
          </fw-panel>

          <fw-panel :title="$t('logs')">
            <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
              <b-tab-item :label="$t('messages')" value="messages">
                <PanelMessages
                  :users="users"
                  :messages="messages"
                  :pagination="pagination.messages"
                  :loading="loading.messages"
                  :show-public-private-toggle="true"
                  :force-hide-private-messages="false"
                  :can-add-message="validations.can_send_messages"
                  :default-add-message-is-private="true"
                  :allow-copy-files="false"
                  @page-changed="messagesPageChanged"
                  @add-message="addMessage"
                  @update-message="updateMessage"
                  @delete-message="deleteMessage"
                />
              </b-tab-item>
              <b-tab-item :label="$t('logs')" value="logs">
                <PanelTeamActivity
                  :activity="logs"
                  :users="users"
                  :pagination="pagination.activity"
                  :loading="loading.activity"
                  class="bg-white rounded-xl"
                  @page-changed="activityPageChanged"
                ></PanelTeamActivity>
              </b-tab-item>
            </b-tabs>
          </fw-panel>
        </div>
      </div>
    </template>

    <template #right-sidebar>
      <div class="p-5 flex-1">
        <div class="flex flex-col items-start">
          <fw-label>Estado da Equipa</fw-label>
          <fw-tag v-if="teamsStates[team.status]" expanded size="lg" :type="teamsStates[team.status].color">{{
            teamsStates[team.status].label[language]
          }}</fw-tag>
        </div>

        <div v-if="validations.can_change_status" class="border-t pt-2 mt-2">
          <fw-label>Decisão</fw-label>
          <div class="grid grid-cols-2 gap-5">
            <div v-if="!validations.stage_is_full">
              <fw-button
                :type="'border-primary'"
                :disabled="$v.team.players.$error"
                expanded
                class="h-full px-4"
                @click.native="openApproveDecisionModal"
                >Aprovar</fw-button
              >
            </div>

            <div>
              <fw-button
                :type="'border-danger'"
                :disabled="$v.team.players.$error"
                expanded
                class="h-full px-4"
                @click.native="openRejectDecisionModal"
                >Rejeitar</fw-button
              >
            </div>
          </div>
        </div>

        <div v-if="team.leader" class="border-t pt-2 mt-3">
          <fw-label>{{ $t('leader') }}</fw-label>
          <Person no-style :person="team.leader" />
        </div>

        <div class="flex flex-col gap-3 text-sm border-t py-3 mt-3 text-gray-600">
          <div>
            <fw-label marginless>Chave da equipa</fw-label>
            <div>{{ team.key }}</div>
          </div>

          <div v-if="team.stage">
            <div class="inline-flex gap-2 justify-between w-full">
              <fw-label marginless>Fase atual</fw-label>
              <fw-button
                v-if="validations.can_move_team && league?.type == 'academic'"
                type="link"
                :disabled="savingData"
                @click.native="openMoveTeamModal()"
              >
                Mover
              </fw-button>
            </div>
            <div :class="{ '-mt-2': validations.can_move_team && league?.type == 'academic' }">
              {{ stages[team.stage][language] }}
            </div>
          </div>

          <div v-if="team.group_key">
            <fw-label marginless>Núcleo</fw-label>
            <div>{{ team.team_group.name }}</div>
          </div>

          <div>
            <fw-label marginless>Data de criação</fw-label>
            <div>{{ team.created_at | formatDateTime }}</div>
          </div>

          <div v-if="team.submitted_at">
            <fw-label marginless>Data de submissão</fw-label>
            <div class="flex items-center gap-1">
              <span>{{ team.submitted_at | formatDateTime }}</span>
              <fw-icon-checkbox-circle class="w-5 h-5 text-primary" />
            </div>
          </div>

          <div v-if="team.confirmed_at">
            <fw-label marginless>{{ $t('confirmedAt') }}</fw-label>
            <div>{{ team.confirmed_at | formatDateTime }}</div>
          </div>

          <div v-if="team.denied_at">
            <fw-label marginless>{{ $t('deniedAt') }}</fw-label>
            <div>{{ team.denied_at | formatDateTime }}</div>
          </div>

          <div v-if="team.deleted_at">
            <fw-label marginless>{{ $t('deletedAt') }}</fw-label>
            <div>{{ team.deleted_at | formatDateTime }}</div>
          </div>
        </div>
      </div>
    </template>

    <template #modals>
      <fw-modal :active.sync="addTeamMemberModalActive" size="md" boxed="xs" @close="closeModal">
        <ModalInviteUser
          v-if="addTeamMemberModalActive"
          :team="team"
          @add-team-member="addTeamMember"
          @close="closeModal"
        ></ModalInviteUser>
      </fw-modal>

      <fw-modal :active.sync="isModalDecisionActive" boxed="sm" size="3xl" width="42rem" @close="closeModal">
        <!-- TODO: Should the message when approving always be required? -->
        <ModalMessage
          v-if="isModalDecisionActive"
          :title="currentModalDecision == 'denied' ? 'Rejeitar inscrição' : 'Aprovar inscrição'"
          :description="
            currentModalDecision == 'denied'
              ? 'Indique a fundamentação da rejeição, que será visualizada pela equipa.'
              : 'Se desejar, escreva uma mensagem personalizada para a equipa.'
          "
          :ignore-confirm-public-message="true"
          :allow-files="false"
          @close="closeModal"
          @save="saveDecision"
        />
      </fw-modal>

      <fw-modal
        :active.sync="isModalEditionSignupActive"
        boxed="sm"
        size="3xl"
        width="42rem"
        @close="closeEditionSignupModal"
      >
        <ModalEditionSignup
          v-if="isModalEditionSignupActive"
          :edition-signup="selectedEditionSignup"
          :user="selectedUser"
          :league="league"
          @close="closeEditionSignupModal"
        />
      </fw-modal>

      <fw-modal :active.sync="isMoveTeamModalActive" boxed="sm" size="3xl" width="42rem" @close="closeMoveTeamModal">
        <ModalMoveTeams
          v-if="isMoveTeamModalActive"
          :tournament-key="tournament.key"
          :stages="tournament.stages"
          :default-from-stage="team.stage"
          :league-key="league.key"
          :team-to-move="team"
          :show-info="false"
          :saving-data="movingTeam"
          emit-update-only
          @close="closeMoveTeamModal"
          @update="moveTeam"
        >
        </ModalMoveTeams>
      </fw-modal>
    </template>
  </fw-layout>
</template>

<script>
import ModalMoveTeams from '@/components/modals/ModalMoveTeams'
import ModalEditionSignup from '@/components/modals/ModalEditionSignup'
import PanelTeamActivity from '@/components/panels/manage/PanelTeamActivity'
import RecordTournament from '@/components/records/RecordTournament'
import PanelManageTeamHeader from '@/components/panels/manage/PanelManageTeamHeader'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/Person'
import SidebarManageTeamsList from '@/components/sidebars/manage/SidebarManageTeamsList'
import ModalInviteUser from '@/components/modals/ModalInviteUser'
import ModalMessage from '@/fw-modules/fw-core-vue/ui/components/modals/ModalMessage'

import { TEAM_STATES, TOURNAMENT_STAGES } from '@/utils/index.js'
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'

export default {
  components: {
    ModalMoveTeams,
    ModalEditionSignup,
    PanelTeamActivity,
    PanelMessages,
    SidebarManageTeamsList,
    Person,
    PanelManageTeamHeader,
    RecordTournament,
    ModalInviteUser,
    ModalMessage
  },

  data() {
    return {
      addTeamMemberModalActive: false,
      isModalDecisionActive: false,
      currentModalDecision: null,
      isModalEditionSignupActive: false,
      isMoveTeamModalActive: false,
      selectedEditionSignup: null,
      selectedUser: null,
      loading: {
        initial: true,
        team: true,
        teams: false,
        messages: false,
        activity: false
      },
      movingTeam: false,
      savingData: false,
      savingStatus: false,

      tournaments: [],
      team: {},
      users: {},
      teams: [],

      tournament: {},
      league: {},

      activeTab: 'messages',

      logs: [],
      messages: [],

      canSendMessages: false,
      teamsStates: TEAM_STATES,
      stages: TOURNAMENT_STAGES,

      validations: {
        can_view_team: true,
        can_edit_team: false,
        can_change_status: true
      },

      edition: {},
      sport: {},
      editionSignups: {},

      appliedTeamsFilters: [
        'status:draft_2',
        'status:submitted',
        'status:approved',
        'status:denied',
        'status:cancelled',
        'status:error'
      ],

      pagination: {
        teams: {
          active_limit: 25,
          current_page: 1,
          total_pages: 0,
          total_items: 0
        },
        messages: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50
        },
        activity: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50
        }
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language || 'pt'
    },
    editionKey() {
      return this.$route.params.editionKey
    },
    leagueKey() {
      return this.$route.params.leagueKey
    },
    tournamentKey() {
      return this.$route.params.tournamentKey
    },
    teamKey() {
      return this.$route.params.teamKey
    },
    notFoundTeam() {
      return !this.loading.team && !this.team
    },
    backToPath() {
      const params = new URL(document.location).searchParams
      if (this.tournamentKey) {
        return `/manage/edition/${this.editionKey}/league/${this.leagueKey}/tournament/${
          this.tournamentKey
        }/teams?${params.toString()}`
      }
      return `/manage/edition/${this.editionKey}/league/${this.leagueKey}/teams?${params.toString()}`
    },
    totalPlayers() {
      if (this.team) return (this.team.players?.length ?? 0) + (this.validInvites?.length ?? 0)
      return 0
    },

    validInvites() {
      return this.team.invites.filter(el => el.status == 'confirmed' || el.status == 'pending')
    },

    isAcademic() {
      return this.league?.type == 'academic'
    }
  },

  validations() {
    return {
      team: {
        name: { required, min: minLength(1), max: maxLength(50) },
        players: {
          required,
          min: value => {
            return !helpers.req(value) || this.totalPlayers >= this.sport?.min_players
          },
          max: value => {
            return !helpers.req(value) || this.totalPlayers <= this.sport?.max_players
          }
        }
        // group_key: {
        //   required: requiredIf(() => {
        //     return this.league?.type == 'academic'
        //   }),
        // },
      }
    }
  },

  watch: {
    teamKey(newTeamKey, oldTeamKey) {
      if (newTeamKey && newTeamKey !== oldTeamKey) {
        this.getTeam()
      }
    }
  },

  async mounted() {
    this.getTeam()
    this.getTeams()
    this.getTournamentOrLeague()
  },

  methods: {
    async getTeams() {
      this.loading.teams = true

      if (this.loading.initial) this.getStatusFromURL()

      const query = {
        limit: this.pagination.teams?.active_limit ?? 25,
        page: this.pagination.teams?.current_page ?? 1,
        ...utils.setFiltersQuery(this.appliedTeamsFilters)
      }

      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await this.api.managementGetTeams(this.editionKey, this.leagueKey, this.tournamentKey, query)
          console.log('managementGetTeams :>> ', response)
          this.teams = response.teams
          this.pagination.teams = response.pagination
          if (response.users) this.users = { ...this.users, ...response.users }
        },
        // Finally
        () => {
          this.loading.teams = false
        }
      )
    },

    async resendInvite(invite) {
      console.log('resendInvite', invite)
      utils.tryAndCatch(this, async () => {
        const response = await this.api.resendTeamInvite(invite.invite_key)
        this.$buefy.toast.open({
          duration: 3000,
          message: `Novo convite enviado para <b>${invite.email}</b>`,
          position: 'is-top'
        })
        console.log('resendInvite :>> ', response)
      })
    },

    teamsPageChanged(page) {
      console.log('teamsPageChanged :>> ', page)
      this.pagination.teams.current_page = page
      this.getTeams()
    },

    getStatusFromURL() {
      if (this.$route.query.page) {
        this.pagination.teams.active_limit = Number(this.$route.query.page)
      }

      if (!this.$route.query.f) {
        this.appliedTeamsFilters = [
          'status:draft_2',
          'status:submitted',
          'status:approved',
          'status:denied',
          'status:cancelled',
          'status:error'
        ]
      } else {
        this.appliedTeamsFilters = this.$route.query.f.split(',')
      }
    },

    openMoveTeamModal() {
      this.isMoveTeamModalActive = true
    },

    closeMoveTeamModal() {
      this.isMoveTeamModalActive = false
    },

    openEditionSignupModal(userKey) {
      if (!this.editionSignups[userKey]) return
      this.isModalEditionSignupActive = true
      this.selectedEditionSignup = this.editionSignups[userKey]
      this.selectedUser = this.users[userKey]
    },

    closeEditionSignupModal() {
      this.isModalEditionSignupActive = false
      this.selectedEditionSignup = null
      this.selectedUser = null
    },

    setupTeamData(response) {
      if (response.edition_signups) this.editionSignups = response.edition_signups
      if (response.team?.validations) this.validations = response.team.validations

      if (response.users) this.users = { ...this.users, ...response.users }
      const leader = response.team?.leader
      let players = {}
      response.team.players.forEach(player => {
        players[player] = this.users[player] ?? { key: player, email: player }
      })

      let invites = []
      if (response.team.invites?.invites) {
        for (const invite of Object.values(response.team.invites.invites).flat()) {
          if (invite.status == 'confirmed') {
            players[invite.user_key] = {
              ...invite,
              ...(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key }),
              invite_key: invite.key
            }
            continue
          }
          invites.push({
            ...invite,
            ...(this.users[invite.user_key] ?? { key: invite.user_key, email: invite.user_key }),
            invite_key: invite.key
          })
        }
      }

      players = Object.values(players)
      this.team = {
        ...response.team,
        players,
        invites,
        leader: this.users[leader] ?? { key: leader, email: leader },
        team_group: response.team_group ?? this.team.team_group
      }

      console.log('team after setupTeamData :>> ', this.team)
    },

    async getTeam() {
      this.loading.team = true
      utils.tryAndCatch(
        this,
        // Main code to run
        async () => {
          const response = await this.api.managementGetTeam(this.teamKey)
          console.log('getTeam :>> ', response)
          console.log('Sport :>> ', this.sport)
          if (response.team.tournament_key !== this.tournamentKey) {
            this.getTournament(response.team.tournament_key)
          }
          this.setupTeamData(response)
          this.getTabData(this.activeTab)
        },
        // Finally
        () => {
          // Run just once
          if (this.loading.initial) {
            setTimeout(() => {
              this.loading.initial = false
            }, 750)
          }

          this.loading.team = false
        }
      )
    },

    async getTabData(tab) {
      if (tab == 'messages') this.getMessages()
      else if (tab == 'logs') this.getActivity()
    },

    getTournamentOrLeague() {
      // Get league or tournament (which already have league details)
      if (this.tournamentKey) {
        console.log('Getting tournament details...')
        this.getTournament()
      } else {
        console.log('Getting league details...')
        this.getLeague()
      }
    },

    async getTournament(tournamentKey = null) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.managementGetTournament(tournamentKey ?? this.tournamentKey)
        console.log('managementGetTournament :>> ', response)
        this.tournament = response.tournament
        this.edition = response.edition
        this.league = response.league
        this.sport = response.sport
      })
    },

    async getLeague() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.managementGetEditionLeague(this.editionKey, this.leagueKey)
        console.log('managementGetEditionLeague :>> ', response)
        delete response.sports
        this.league = response
      })
    },

    goToTournament(tournamentKey) {
      const route = this.$router.resolve({
        name: 'manage-tournament',
        params: {
          editionKey: this.editionKey,
          leagueKey: this.leagueKey,
          tournamentKey: tournamentKey,
          view: 'dashboard'
        }
      })

      window.open(route.href, '_blank')
    },

    async getMessages() {
      this.loading.messages = true

      utils.tryAndCatch(
        this,
        // Code to run on try
        async () => {
          const response = await this.api.managementGetTeamMessages(this.teamKey, {
            page: this.pagination.messages.page,
            limit: this.pagination.messages.limit
          })
          console.log('managementGetTeamMessages :>> ', response)
          this.messages = response.messages

          // Add user to users list to make sure we got user details after first message
          this.users = { ...this.users, ...response.users, ...{ [this.user.key]: this.user } }

          this.pagination.messages = {
            limit: response.pagination.active_limit,
            page: response.pagination.current_page,
            totalResults: response.pagination.total_items,
            totalPages: response.pagination.total_pages
          }
        },

        // Finally
        () => {
          this.loading.messages = false
        }
      )
    },

    downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    },

    messagesPageChanged(page) {
      console.log('messagesPageChanged :>> ', page)
      this.pagination.messages.page = page
      this.getMessages()
    },

    async addMessage(messageData) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.managementSendTeamMessages(
          this.teamKey,
          messageData.description,
          messageData.files,
          messageData.is_private
        )
        console.log('response :>> ', response)
        console.log('addedMessage :>> ', response)
        this.messages.unshift(response)
        this.$emit('added-message', response)
      } catch (error) {
        this.savingMessageError = true
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', error)
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `Não foi possível guardar a mensagem: ${errorKey}`,
          type: 'is-danger',
          duration: 4000
        })
      }
    },

    async updateMessage(messageData) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.managementUpdateTeamMessage(
          this.teamKey,
          messageData.key,
          messageData.description,
          messageData.files,
          messageData.is_private
        )
        console.log('updatedMessage :>> ', response)
        let index = this.messages.findIndex(el => el.key == response.key)
        if (index > -1) this.$set(this.messages, index, response)
      })
    },

    async deleteMessage(message) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.managementDeleteTeamMessage(this.teamKey, message.key)
        console.log('deleteApplicationMessage :>> ', response)
        this.messages = this.messages.filter(el => el.key != message.key)
      })
    },

    async getActivity() {
      this.loading.activity = true

      utils.tryAndCatch(
        this,
        // Code to run on try
        async () => {
          const response = await this.api.managementGetTeamActivity(this.teamKey, {
            page: this.pagination.activity.page,
            limit: this.pagination.activity.limit
          })
          this.logs = response.logs
          this.users = { ...this.users, ...response.users }
          this.pagination.activity = {
            limit: response.pagination.active_limit,
            page: response.pagination.current_page,
            totalResults: response.pagination.total_items,
            totalPages: response.pagination.total_pages
          }
        },

        // Finally
        () => {
          this.loading.activity = false
        }
      )
    },

    activityPageChanged(page) {
      console.log('activityPageChanged :>> ', page)
      this.pagination.activity.page = page
      this.getActivity()
    },

    openApproveDecisionModal() {
      this.currentModalDecision = 'approved'
      this.isModalDecisionActive = true
    },

    openRejectDecisionModal() {
      this.currentModalDecision = 'denied'
      this.isModalDecisionActive = true
    },

    saveDecision(message) {
      console.log('saveDecision :>> ', message)
      if (this.currentModalDecision == 'denied') {
        this.confirmReject(message.description)
      } else {
        this.confirmApprove(message.description)
      }
    },

    confirmApprove(message) {
      this.$buefy.dialog.confirm({
        title: 'Aprovar equipa',
        message: `Tem a certeza que deseja aprovar a inscrição da equipa <strong>"${this.team.name}"</strong> `,
        confirmText: 'Aprovar',
        onConfirm: async () => {
          console.log('approve me')
          this.changeStatus('approved', message)
          this.closeModal()
        }
      })
    },

    confirmReject(message) {
      this.$buefy.dialog.confirm({
        title: 'Rejeitar equipa',
        message: `Tem a certeza que deseja rejeitar a inscrição da equipa <strong>"${this.team.name}"</strong> `,
        confirmText: 'Rejeitar',
        type: 'is-danger',
        onConfirm: async () => {
          this.changeStatus('denied', message)
          this.closeModal()
        }
      })
    },

    async changeStatus(newStatus, message = '') {
      console.log('changeStatus to :>> ', newStatus, message)
      this.savingStatus = true
      await utils.tryAndCatch(
        this,
        async () => {
          this.errorMessage = null
          const response = await this.api.managementUpdateTeamStatus(this.team.key, newStatus, message)
          this.$buefy.toast.open({
            message: 'Decisão atualizada com sucesso.',
            type: 'is-success',
            position: 'is-top'
          })

          console.log('managementUpdateTeamStatus :>> ', response)
          this.team.status = response.status
          this.updateStatusOnTeamsList(this.team.key, response.status)
          if (response.validations) this.validations = response.validations
        },
        () => {
          this.savingStatus = false
        },
        error => {
          console.log('errorData :>> ', error)
          if (error && error.length > 0) {
            const errorKey = error[0].key
            console.log('errorKey :>> ', errorKey)
            if (errorKey == 'UserAlreadyInTeam') {
              const user = this.users[error[0].email]
              this.$buefy.dialog.alert({
                title: 'Ocorreu um erro',
                message: `O utilizador <b>${user ? user.name : error[0].email}</b> já faz parte de outra equipa.`,
                type: 'is-danger'
              })

              return
            }
          }

          utils.errorDialogAlert(this, error, false)
        },
        false
      )
    },

    updateStatusOnTeamsList(teamKey, newStatus) {
      const team = this.teams.find(el => el.key == teamKey)
      if (team) team.status = newStatus
    },

    async confirmUpdateTeam(newPlayerEmail, removePlayerEmail) {
      if (this.team.status != 'approved') return await this.updateTeam(newPlayerEmail, removePlayerEmail)
      this.$buefy.dialog.confirm({
        confirmText: 'Continuar',
        type: 'is-danger',
        cancelText: 'Cancelar',
        message: `Esta ação vai alterar o estado da equipa de <strong>"${
          this.teamsStates[this.team.status].label[this.language]
        }"</strong> para <strong>"${
          this.teamsStates['submitted'].label[this.language]
        }"</strong>. Tem a certeza que deseja continuar?`,
        onConfirm: async () => {
          return await this.updateTeam(newPlayerEmail, removePlayerEmail)
        }
      })
    },

    async addTeamMember(playerEmail) {
      await this.confirmUpdateTeam(playerEmail, null)
      if (this.errorMessage) return
      this.closeModal()
    },

    removeTeamMember(player) {
      console.log('removeTeamMember', player)
      this.confirmUpdateTeam(null, player.email)
    },

    setLeader(player) {
      this.team.leader = player
      this.confirmUpdateTeam()
    },

    removeLogo() {
      console.log('remove-logo')
      this.$set(this.team, 'logo', null)
      this.confirmUpdateTeam()
    },

    selectColor(color) {
      console.log('select color :>> ', color)
      this.$set(this.team, 'color', color)
    },

    updateLogo(uploadedFiles) {
      const file = uploadedFiles[0]
      this.team.logo = file.response.data.file
      this.confirmUpdateTeam()
    },

    async updateTeam(newPlayerEmail = null, removePlayerEmail = null) {
      this.$v.$touch()
      if (this.$v.team.name.$invalid) return

      this.savingData = true

      let teamData = {
        name: this.team.name,
        logo_key: this.team.logo?.key,
        color: this.team.color,
        players_emails: [],
        leader: this.team.leader?.key ?? this.team.leader?.email,
        submit: true
      }

      console.log({ newPlayerEmail, removePlayerEmail })

      let players = (this.team.players ?? []).concat(this.team.invites ?? []).map(e => e.email)
      if (removePlayerEmail) {
        players = players.filter(el => el != removePlayerEmail)
      }

      if (newPlayerEmail) {
        players.push(newPlayerEmail)
      }

      teamData.players_emails = Array.from(new Set(players))

      console.log('this.savingData :>> ', this.savingData)
      utils.tryAndCatch(
        this,
        async () => {
          this.errorMessage = null
          const response = await this.api.managementUpdateTeam(this.team.key, teamData)
          if (response?.['__errors__']?.length) {
            this.setTeamErrors(response['__errors__'])
          } else {
            this.$buefy.toast.open({
              message: newPlayerEmail ? 'Membro adicionado com sucesso.' : 'Equipa atualizada com sucesso.',
              type: 'is-success',
              position: 'is-top'
            })
            console.log('updateTeam :>> ', response)
            this.setupTeamData(response)
            if (response.validations) this.validations = response.validations
          }
        },
        () => {
          this.savingData = false
        },
        error => {
          this.setTeamErrors(error.response.data['__errors__'])
        }
      )
    },

    async moveTeam(payload) {
      utils.tryAndCatch(
        this,
        async () => {
          this.movingTeam = true
          const response = await this.api.managementUpdateTeamStage(this.team.key, payload.to_stage)
          this.closeMoveTeamModal()
          this.$buefy.toast.open({
            message: 'Equipa atualizada com sucesso.',
            type: 'is-success',
            position: 'is-top'
          })

          console.log('this.team.stage', this.team.stage)
          console.log('payload.to_stage_type', payload.to_stage_type)
          this.team.stage = payload.to_stage_type
          console.log('managementUpdateTeamStage :>> ', response)
        },
        () => {
          this.movingTeam = false
        }
      )
    },

    closeModal() {
      this.addTeamMemberModalActive = false
      this.isModalDecisionActive = false
      this.currentModalDecision = null
    },

    goToTeam(teamKey) {
      let routeName = 'manage-edition-league-team'
      let routeParams = {
        editionKey: this.editionKey,
        leagueKey: this.leagueKey,
        teamKey: teamKey
      }

      const query = {}
      if (this.appliedTeamsFilters.length > 0) {
        query['f'] = this.appliedTeamsFilters.join(',')
      }

      if (this.tournamentKey) {
        routeName = 'manage-edition-league-tournament-team'
        routeParams.tournamentKey = this.tournamentKey
      }

      this.$router.push({
        name: routeName,
        params: routeParams,
        query: query
      })
    },

    setTeamErrors(errorData) {
      console.error('setTeamErrors :>> ', errorData)
      let errorMessage = this.$t('errors.default')
      if (errorData && errorData.length > 0) {
        const errorKey = errorData[0].key

        switch (errorKey) {
          case 'TeamNameAlreadyExists':
            errorMessage = this.$t('errors.teamNameAlreadyExists')
            break
          case 'Forbidden':
            errorMessage = this.$t('errors.noPermissionsToCreate')
            if (errorData[0].detail == 'Already leader of a team') {
              errorMessage = this.$t('errors.alreadyLeaderOfTeam')
            }
            break
          case 'UserAlreadyInTeam':
            errorMessage = this.$t('errors.userAlreadyInTeam', {
              email: errorData[0].email
            })
            break
          case 'InvalidEmail':
            errorMessage = this.$t('errors.invalidEmail')
            // Only allow students in academic league. Ignore for local dev
            if (errorData[0].detail.includes('Only students')) {
              errorMessage = this.$t('errors.onlyStudents')
            }
            break

          default:
            break
        }
      }

      this.$buefy.dialog.alert({
        message: errorMessage,
        type: 'is-danger'
      })
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "leader": "Líder",
    "confirmedAt": "Confirmada em",
    "deniedAt": "Rejeitada em",
    "deletedAt": "Removida em",
    "messages": "Mensagens",
    "logs": "Atividade",
		"tournament": "Torneio",
    "errors": {
      "default": "Ocorreu um erro ao inscrever a equipa.",
      "tournamentNotFound": "O torneio não foi encontrado.",
      "invalidOrganicUnit": "A unidade orgânica selecionada não é válida.",
      "invalidTournamentStage": "O torneio não se encontra na fase de inscrições.",
      "signupNotAvailable": "As inscrições para o torneio não estão disponíveis.",
      "teamNameAlreadyExists": "Já existe uma equipa com esse nome.",
      "alreadyLeaderOfTeam": "Já é líder de uma equipa.",
      "noPermissionsToCreate": "Não tem permissão para editar a equipa.",
      "minPlayers": "Uma equipa tem de ter pelo menos {min_number} jogadores. Apenas {total} de {min_number} adicionados",
      "invalidEmail": "E-mail inválido.",
      "onlyStudents": "Apenas podem ser convidados estudantes.",
      "userAlreadyInTeam": "O utilizador {email} já faz parte de outra equipa.",
			"duplicatedPlayers": "Existem jogadores duplicados em equipas da mesma modalidade.",
			"stageIsFull": "Já foi atingido o número máximo de equipas nesta fase."
    }
  },
  "en": {
    "leader": "Leader",
    "confirmedAt": "Confirmed at",
    "deniedAt": "Denied at",
    "deletedAt": "Deleted at",
    "messages": "Messages",
    "logs": "Activity",
		"tournament": "Tournament",
    "errors": {
      "default": "An error occurred when registering the team.",
      "tournamentNotFound": "The tournament could not be found.",
      "invalidOrganicUnit": "The organic unit selected is not valid.",
      "invalidTournamentStage": "The tournament is not at the registration stage.",
      "signupNotAvailable": "Registration for the tournament is not available.",
      "teamNameAlreadyExists": "There is already a team with that name.",
      "alreadyLeaderOfTeam": "You are already a team leader.",
      "noPermissionsToCreate": "You don't have permissions to edit this team.",
      "minPlayers": "A team must have at least {min_number} players. Just {total} of {min_number} added",
      "invalidEmail": "Invalid e-mail address.",
      "onlyStudents": "Only students can be invited.",
      "userAlreadyInTeam": "The user {email} is already in a team.",
			"duplicatedPlayers": "There are duplicate players in teams of the same sport",
			"stageIsFull": "The maximum number of teams has been reached for this stage"
    }
  }
}
</i18n>
