<template>
  <div class="flex flex-col h-full">
    <div class="flex gap-5 items-center px-5 p-5 lg:px-8 lg:pt-8 lg:pb-7">
      <fw-avatar :size="'lg'" :user="user"></fw-avatar>
      <div class="flex-1 my-2">
        <div class="flex gap-2">
          <fw-heading size="h2" class="flex-1">
            {{ user.display_name || user.full_name }}
          </fw-heading>
        </div>
        <div class="md:flex gap-5">
          <div class="font-medium break-all max-w-lg">{{ user.email }}</div>
          <div class="font-medium text-sm flex gap-5 text-gray-500 flex-shrink-0 leading-6">
            <div v-if="user.type === 'uc' && user.number" class="flex-shrink-0">Nº {{ user.number }}</div>
            <div class="flex-shrink-0">
              <a
                v-if="user.meeting"
                :href="getUserMeetingRoomUrl()"
                class="flex items-center gap-1"
                title="Sala na UC Meetings"
                target="_black"
              >
                <fw-icon-live class="w-5 h-5" />
                {{ user.meeting.key_alias || user.meeting.key }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <fw-panel title="Informação pessoal" boxed="sm" custom-class="bg-gray-100 flex flex-col gap-3">
      <!-- <div>
        <fw-label marginless>{{ $t('name') }}</fw-label>
        <fw-panel-info size="normal" clean>{{ user.full_name }}</fw-panel-info>
      </div>

      <div>
        <fw-label marginless>{{ $t('email') }}</fw-label>
        <fw-panel-info size="normal" clean>{{ user.email }}</fw-panel-info>
      </div> -->

      <div v-if="editionSignup.extra_data.student_number">
        <fw-label marginless>{{ $t('studentNumber') }}</fw-label>
        <fw-panel-info size="normal" clean>{{ editionSignup.extra_data.student_number }}</fw-panel-info>
      </div>

      <div v-if="editionSignup.extra_data.company_name">
        <fw-label marginless>{{ $t('companyName') }}</fw-label>
        <fw-panel-info size="normal" clean>{{ editionSignup.extra_data.company_name }}</fw-panel-info>
      </div>

      <div v-if="editionSignup.extra_data.residence_name">
        <fw-label marginless>{{ $t('residenceName') }}</fw-label>
        <fw-panel-info size="normal" clean> {{ editionSignup.extra_data.residence_name }}</fw-panel-info>
      </div>

      <div>
        <fw-label marginless>{{ $t('birthday') }}</fw-label>
        <fw-panel-info size="normal" clean>
          {{ editionSignup.birthday | formatDate }}
        </fw-panel-info>
      </div>

      <div>
        <fw-label marginless>{{ $t('gender') }}</fw-label>
        <fw-panel-info size="normal" clean>
          {{
            editionSignup.gender == 'M' ? 'Masculino' : editionSignup.gender == 'F' ? 'Feminino' : 'Outros'
          }}</fw-panel-info
        >
      </div>

      <div>
        <fw-label marginless>{{ $t('phone') }}</fw-label>
        <fw-panel-info size="normal" clean>{{ editionSignup.phone_number }}</fw-panel-info>
      </div>
    </fw-panel>
  </div>
</template>

<script>
export default {
  name: 'UserDetails',
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    editionSignup: {
      type: Object,
      default: () => {}
    },
    league: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: true,
      selectedLeagues: [],
      selectedTeamGroups: []
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language || 'pt'
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "leagues": "Ligas",
    "noLeagues": "Sem ligas atribuídas.",
    "teamGroups": "Núcleos",
    "teamGroupsToAdd": "Núcleos",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo",
    "role": {
      "league-manager": "Gestor da(s) fila(s)"
    },
    "chooseAtLeastOneLeague": "Escolha pelo menos uma fila",
    "chooseAtLeastOneTeamGroup": "Escolha pelo menos um núcleo",
    "name": "Nome",
    "email": "E-mail",
    "birthday": "Data de nascimento",
    "gender": "Género",
    "studentNumber": "Número de estudante",
    "companyName": "Nome da empresa",
    "residenceName": "Nome da residência",
    "phoneCountry": "Indicativo",
    "phone": "Número de telemóvel",
    "address": "Morada",
    "locality": "Localidade",
    "country": "País",
    "postalCode": "Código postal",
    "documents": "Comprovativo(s) de cumprimento de requisitos"
  },
  "en": {
    "leagues": "Leagues",
    "noLeagues": "No leagues assigned.",
    "teamGroups": "Núcleos",
    "teamGroupsToAdd": "Núcleos",
    "selectTeamGroupsToAdd": "Selecione pelo menos um núcleo",
    "role": {
      "league-manager": "League manager"
    },
    "chooseAtLeastOneLeague": "Choose at least one league",
    "chooseAtLeastOneTeamGroup": "Escolha pelo menos um núcleo",
    "name": "Nome",
    "email": "E-mail",
    "birthday": "Data de nascimento",
    "gender": "Género",
    "studentNumber": "Número de estudante",
    "companyName": "Nome da empresa",
    "residenceName": "Nome da residência",
    "phoneCountry": "Country code",
    "phone": "Phone number",
    "address": "Address",
    "postalCode": "Postal code",
    "locality": "Locality",
    "country": "Country",
    "documents": "Comprovativo(s) de cumprimento de requisitos"
  }
}
</i18n>
